@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.overlay-container {
  margin-top: 4vw;
  margin-bottom: 2vw;
  position: relative;
  width: 100%;
  height: calc(100% - 6vw);
  box-sizing: border-box;
  overflow: auto;
  /*stop scrolling horizontal*/
  overflow-x: hidden;
}

.editTextArea {
  font: unicode;
    font-family: monospace;
  /* font-family: 'Geologica', monospace; */
  font-size: 1.1rem;
  font-weight: 250;
  white-space: break-spaces;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 90%;
  height: auto;
  border-radius: 5px;
  border: none;
  resize: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  z-index: 2;
  /* Ensure textarea is above */
  autocomplete: off;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 3vh;
}

.editTextArea::placeholder{
  color: #c0cc9d;
}

.suggestion {
  font: unicode;
    font-family: monospace;
  /* font-family: 'Geologica', monospace; */
  font-size: 1.1rem;
  font-weight: 250;
  white-space: break-spaces;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 90%;
  height: auto;
  border-radius: 5px;
  border: none;
  resize: none;
  outline: none;
  background-color: transparent;
  color: #c0cc9d;
  z-index: 1;
  overflow: hidden;
  /* Lower z-index to be below textarea */
  box-sizing: border-box;
  margin-bottom: 3vh;
}

@media (max-width: 768px) {
  .editTextArea {
    font-size: 0.9rem;
  }
  .suggestion {
    font-size: 0.9rem;
  }
}
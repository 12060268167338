.signup {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    /* border: 1px solid grey; */
    /* padding: 50px;
    margin-right: 30px;
    margin-left: 30px; */
  }
  
  .signup > img {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
  
  .signup > input {
    background-color: #ffffff24;
    border: 1px solid grey;
    border-radius: 3px;
    padding: 12px;
    margin-bottom: 10px;
    color: white;
  }
  
  .signup > input:focus {
    outline: 1px solid grey;
  }
  
  .signup > button {
    padding: 12px;
    border-radius: 8px;
    border: 0;
    font-weight: bolder;
    color: white;
    background-color: #0095f6;
  }
  
  .signup > button:hover {
    cursor: pointer;
  }
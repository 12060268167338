.login-modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
  }
  
  .login-modal-main {
    position:fixed;
    background: rgb(195, 203, 169);
    border-radius: 22px;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: 4vw 5vw;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .login-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.login-modal-content form {
    display: flex;
    flex-direction: column;
    width: auto;
    /* padding top and bottom 20 px, padding left and right 100px */
    padding: 20px 100px;
    margin: auto;

}

.login-modal-content div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.login-modal-content label {
    margin-bottom: 10px;
    color: #717365;
}

.login-modal-content input[type="text"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 25vw; /* Adjust as needed */
    height: 35px; /* Adjust as needed */
}

.login-modal-content input[type="submit"] {
    /* center within parent */
    margin: auto;
    width: 25vw;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #54d28d;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    max-width: 300px;
    height: 50px;
}

.login-modal-content input[type="submit"]:hover {
    background-color: #45a049;
}

/* h1 */
.login-modal-content h1 {
    margin-bottom: 20px;
    color: #717365;
}

/* From Ma's auth */
.authenticate {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 50px 0px 50px;
}

.auth__left {
  flex: 0.5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth__right {
  flex: 0.5;
}

.auth__left > img {
  width: 75px;
  margin-top: 40px;
}

.auth__more {
  /* border: 1px solid grey; */
  padding: 20px;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 13px;
  text-align: center;
}

.auth__more > span {
  font-weight: 500;
  font-size: 14px;
}

.auth__more > span > button {
  background: 0;
  border: 0;
  font-weight: bolder;
  color: #0095f6;
}

.auth__more > span > button:hover {
  cursor: pointer;
}
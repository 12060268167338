.note-view {
    margin: 20px;
    padding: 20px;
    height: calc(100vh - 40px - 40px);/* Subtract margins and padding */
    overflow: auto;
}

.action-bar {
    position: absolute;
    margin: 1%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;

    z-index: 88;
}

.save-button{
    background-color: #bbdcb6;
    border: 2px solid #bbdcb6;
    color: white;
}

.delete-button{
    background-color: #e69797;
    border: 2px solid #e69797;
    color: white;
}

.save-button, .delete-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3.5vw; 
    height: 3.5vw;
    border-radius: 50%;
    margin: 0 0.5vw;
    font-size: 0;
    transition: 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        background-color: white;
        /* inner shadow */
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.90);
        color: rgb(71, 71, 71);
        font-size: 0.85vw;
        font-weight: bold;
    }
}

.note-page {
    margin: 0 auto; 
    position: relative;
    height: 100%;
    max-width: 900px;
    padding: 25px;
    border-radius: 3%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.60);
    box-sizing: border-box;
    overflow: hidden;
}

.smart-text-area {

}

@media screen and (max-width: 750px) {
    .note-view {
        margin: 0px;
        padding: 0;
        height: 100vh;
    }
    .note-page {
        padding: 10px;
        padding-top: 20px;
    }
    .save-button, .delete-button {
        /* make buttons darker */
        background-color: #7f897c;

        font-size: 1.8vw;
        width: 9vw;
        height: 9vw;
        font-weight: 1000;
        
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.90);
    }
}
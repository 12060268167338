body {
    font-family: Arial, sans-serif;
}

.market {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
}

.market-header {
    margin-bottom: 20px;
}

.market-header h1 {
    color: #f5fcee;
    font-size: 2em;
}

.market-body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
}

.market-plugin {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    background-color: rgb(195, 203, 169);
}

.market-plugin h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.market-plugin p {
    color: #666;
    margin-bottom: 20px;
}

.market-button {
    background-color: #54d28d; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}
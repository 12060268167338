.app-container{
  background-color: #717365;
  height: 100vh;
  overflow: auto;
}

.full-height {
  height: 100%;
}

.main-container{
  display: grid;
  grid-template-columns: 5vw 1fr;
  transition: all 0.3s ease-in-out;
  height: 100vh;
  overflow: auto;
}

.main-container.show{
  grid-template-columns: 20vw 1fr;
}

.Sidebar{
  grid-column: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.main-view{
  grid-column: 2;
  width: 100%;
  overflow: auto;
}

.modal{
  z-index: 100;
}

@media screen and (max-width: 750px) {
  .main-container{
    grid-template-columns: 10vw 1fr;
  }
  .main-container.show{
    grid-template-columns: 45vw 1fr;
  }
}
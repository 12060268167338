.sidebar{
    top: 0;
    left: 0;
    bottom: 0;
    width: 20vw;
    height: 100vh;
    color: aliceblue;
    background-color:#575e55;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

/* view button on right top of "parent" sidebar */
.row-button{
    top: 0;
    right: 0;
    width: 3vw;
    height: 3vw;
    margin: 1vw;

    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: 25%;
    background-color: #7f897c;
    color: aliceblue;
    border: none;
    outline: none;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    /* hover change color */
    transition: background-color 0.5s ease;

    &:hover{
        background-color: #bbdcb6;
    }


    
}

.row-button img {
    min-width: 2.5vw;
    min-height: 2.5vw;
    width: 90%;
    height: 90%;
    object-fit: contain;
}

.sidebar-icon {
    max-width: 100%;
    max-height: 100%;
}

.slide-button{
    background-color: #bbdcb6;
    color: #7f897c;

    &:hover{
        background-color: #ffffff;
    }
}

.button-row {
    display: flex;
    align-items: center;
}

.button-row h1 {
    font-size: 2.5vw;
}

.button-text {
    margin-left: 10px; /* Adjust as needed */
    font-size: 1.5vw;
}

.bottom-row {
    position: absolute;
    bottom: 0;
    width: 20vw;
}

/* Make child row button smaller than parent row button and a little to the right */
.child-row-button {
    width: 2vw;
    height: 2vw;
    margin-left: 2vw;
}

.notes-container{
    display: none;
    width: 100%;
    height: auto;
    max-height: calc(5 * 40px); /* Adjust this value based on the height of your button rows */
    overflow-y: auto;
}

@media screen and (max-width: 750px) {
    .sidebar{
        width: 45vw;
    }
    .notes-container{
        display: none;
    }
    .notes-container.hide{
        display: none;
    }
    .notes-container.show{
        display: block;
    }
    .row-button{
        width: 8vw;
        height: 8vw;
    }
    .row-button img {
        min-width: 20px;
        min-height: 20px;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .child-row-button{
        width: 6vw;
        height: 6vw;
    }
    .button-row h1 {
        font-size: 5vw;
    }
    .button-text {
        margin-left: 20px; /* Adjust as needed */
        font-size: 3vw;
    }
    .bottom-row {
        width: 45vw;
    }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: rgb(195, 203, 169);
  border-radius: 22px;
  width: auto;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 4vw 5vw;
}

.modal-content {
  padding: 20px;
}

.close-button {
  float: right;
  font-size: 14px;
  font-weight: bold;
  padding: 1vw 2vw;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #267568;
  color: white;
  border: none;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@media screen and (max-width: 750px) {
  .modal-main {
    width: 80%;
  }
  .modal-content {
    padding: 5px;
    font-size: 12px;
  }
  .close-button {
    font-size: 12px;
    padding: 15px;
  }
}